import { Button } from 'antd'
import { SubmitHandler, useForm } from 'react-hook-form'

import { CounterDeviceService } from '@/services'

import { IDevice, IDeviceAdd } from '@/types'

import { requiredField } from '@/utils'

export const CounterDeviceModal = ({
	device,
	closeModal,
}: {
	device?: IDevice
	closeModal: () => void
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IDeviceAdd>({
		mode: 'all',
		defaultValues: device,
	})

	const onSubmit: SubmitHandler<IDeviceAdd> = async (data) => {
		device
			? CounterDeviceService.editById({ ...data, id: device.id }, closeModal)
			: CounterDeviceService.add(data, closeModal)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="login__input-wrap input" style={{ marginBottom: '15px' }}>
				<label className="input__label" htmlFor="name">
					Название
				</label>
				<input
					{...register('name', requiredField)}
					className="input__input"
					type="text"
					placeholder="Название счетчика"
					id="name"
				/>
				{errors.name && (
					<div className="input__error">{errors.name.message}</div>
				)}
			</div>
			<div className="login__input-wrap input">
				<label className="input__label" htmlFor="serialNo">
					Серийный номер
				</label>
				<input
					{...register('serialNo', requiredField)}
					className="input__input"
					type="text"
					placeholder="Серийный номер"
					id="number"
				/>
				{errors.serialNo && (
					<div className="input__error">{errors.serialNo.message}</div>
				)}
			</div>
			<div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
				<Button htmlType="button" onClick={closeModal}>
					Отмена
				</Button>
				<Button htmlType="submit" style={{ marginLeft: '10px' }} type="primary">
					{device ? 'Сохранить' : 'Добавить'}
				</Button>
			</div>
		</form>
	)
}
