import { Button, Modal, Table, TableColumnsType } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { CounterDeviceModal, Loader } from '@/components'

import { CounterDeviceService } from '@/services'

import { IDevice } from '@/types'

export const CounterScreen = () => {
	const [data, setData] = useState<IDevice[]>([])
	const [propsModal, setPropsModal] = useState<IDevice>()
	const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false)
	const [isLoad, setIsLoad] = useState<boolean>(true)

	const fetch = useCallback(async () => {
		const data: IDevice[] = await CounterDeviceService.getAll()
		setData(
			data
				.sort((a, b) => {
					return a.id - b.id
				})
				.map((item) => {
					return {
						...item,
						status: item.status === 'Online' ? 'Онлайн' : 'Офлайн',
					}
				})
		)
		setIsLoad(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const closeModal = useCallback(() => {
		setIsOpenPopUp(false)
		fetch()
		setPropsModal(undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns: TableColumnsType<IDevice> = [
		{
			title: 'Название',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: 'MAC',
			key: 'macAddress',
			dataIndex: 'macAddress',
		},
		{
			title: 'Серийный номер',
			key: 'serialNo',
			dataIndex: 'serialNo',
		},
		{
			title: 'IP',
			key: 'ipAddress',
			dataIndex: 'ipAddress',
		},
		{
			title: 'Активность',
			key: 'status',
			dataIndex: 'status',
		},
		{
			width: 220,
			render: (props) => (
				<div className="counter__btn">
					<Button
						onClick={() => {
							setIsOpenPopUp(true)
							setPropsModal(props)
							console.log(props)
						}}
					>
						Изменить
					</Button>
					<Button
						danger
						onClick={async () =>
							await CounterDeviceService.deleteById(props.id).then(() =>
								fetch()
							)
						}
					>
						Удалить
					</Button>
				</div>
			),
		},
	]

	if (isLoad) return <Loader />

	return (
		<section className="counter">
			<h1 className="counter__title _h3">Счетчики</h1>
			<Button className="counter__add" onClick={() => setIsOpenPopUp(true)}>
				Добавить счетчик
			</Button>
			<Table
				className="profile__table"
				dataSource={data}
				columns={columns}
				pagination={false}
				bordered={true}
				tableLayout={'fixed'}
				locale={{ emptyText: 'Нет данных' }}
				rowKey="id"
			/>
			<Modal
				title={propsModal ? 'Редактирование счетчика' : 'Добавление счетчика'}
				open={isOpenPopUp}
				footer={null}
				onOk={closeModal}
				onCancel={closeModal}
				//@ts-ignore
				onClose={closeModal}
				destroyOnClose={true}
			>
				<CounterDeviceModal device={propsModal} closeModal={closeModal} />
			</Modal>
		</section>
	)
}
