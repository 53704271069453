import { notification } from 'antd'

export const openNotification = (message: string, description: string) => {
	notification.open({
		message: message,
		description: description,
	})
}

export const openSuccessNotification = (
	description: string,
	message: string = 'Успешно'
) => {
	notification.success({
		message: message,
		description: description,
	})
}

export const openErrorNotification = (
	description: string,
	message: string = 'Ошибка'
) => {
	notification.error({
		message: message,
		description: description,
	})
}
