import { Suspense } from 'react'
import { Navigate, createBrowserRouter } from 'react-router-dom'

import { LayoutPages, MainLayout } from '@/layout'

import {
	AUTH_PATH,
	COUNT_PATH,
	ERROR_PATH,
	MAIN_PATH,
	REPORT_PATH,
} from '@/const'

import { Loader } from './components'
import {
	AuthScreen,
	CounterScreen,
	HomeScreen,
	NotFound,
	ReportScreen,
} from './pages'

const router = createBrowserRouter([
	{
		path: MAIN_PATH,
		element: <MainLayout />,
		errorElement: <Navigate to={ERROR_PATH} replace />,
		children: [
			{
				path: MAIN_PATH,
				element: <LayoutPages />,
				children: [
					{
						path: MAIN_PATH,
						element: (
							<Suspense fallback={<Loader />}>
								<HomeScreen />
							</Suspense>
						),
					},
					{
						path: COUNT_PATH,
						element: (
							<Suspense fallback={<Loader />}>
								<CounterScreen />
							</Suspense>
						),
					},
					{
						path: REPORT_PATH,
						element: (
							<Suspense fallback={<Loader />}>
								<ReportScreen />
							</Suspense>
						),
					},
				],
			},
			{
				path: AUTH_PATH,
				element: <AuthScreen />,
			},
		],
	},

	{
		path: ERROR_PATH,
		element: <NotFound />,
	},
])

export default router
