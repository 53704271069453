import { instance } from './axios'

export const authAPI = {
	me() {
		return instance.get(`auth/me`)
	},

	login(email: string, password: string) {
		return instance.post('auth/login', {
			email,
			password,
		})
	},

	logOut() {
		return instance.post(`auth/logout`)
	},

	register({
		login,
		password,
		fullname,
		user_type,
		groups_ids,
	}: {
		login: string
		password: string
		fullname: string
		user_type: number
		groups_ids: Array<number>
	}) {
		return instance.post('auth/register', {
			login,
			password,
			fullname,
			user_type,
			groups_ids,
		})
	},

	update(
		{
			login,
			password,
			fullname,
			user_type,
			groups_ids,
		}: {
			login: string
			password: string
			fullname: string
			user_type: number
			groups_ids: Array<number>
		},
		id: number
	) {
		return instance.post('auth/update_user', {
			user_id: id,
			login,
			password,
			fullname,
			user_type,
			groups_ids,
		})
	},
}
