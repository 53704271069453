import { ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale/ru_RU'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Header } from '@/components'
import { Loader } from '@/components'

import { initializeApp, useAppDispatch, useAppSelector } from '@/store'

import { isShowMenu } from '../../store/appReducer'

export const MainLayout = () => {
	const loading = useAppSelector((state) => state.app.loading)
	const isInit = useAppSelector((state) => state.app.init)
	const isMob = useAppSelector((state) => state.app.typeApp)
	const location = useLocation()

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(initializeApp())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		dispatch(isShowMenu(false))
		document.getElementById('body')?.classList.remove('_fixed')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	if (!isInit) {
		return <Loader />
	}

	return (
		<>
			{loading ? <Loader /> : ''}
			<ConfigProvider locale={ru_RU}>
				<main className="main">
					{isMob === 'mob' ? <Header /> : ''}
					<Outlet />
				</main>
			</ConfigProvider>
		</>
	)
}
