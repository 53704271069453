import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import {
	openErrorNotification,
	openSuccessNotification,
} from 'utils/Notification'

import { jwtInterface } from '@/types'

import { authAPI } from '@/api'

import { loadingStatus } from './appReducer'

export interface InitialStateType {
	isAuth: boolean
	email: string | null | undefined
	userId: string | null | undefined
	error: string | null
	role: string | null | undefined
	inLogging: boolean
	inRegistration: boolean
}

const initialState: InitialStateType = {
	isAuth: false,
	email: null,
	userId: null,
	error: null,
	role: null,
	inLogging: false,
	inRegistration: false,
}

export const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload
		},
		setAuthData: (
			state,
			action: PayloadAction<{
				role: string | null | undefined
				email: string | null | undefined
				userId: string | null | undefined
			}>
		) => {
			state.role = action.payload.role
			state.email = action.payload.email
			state.userId = action.payload.userId
		},
	},
})

export const { setAuth, setAuthData } = authReducer.actions

export const login =
	({ email, password }: { email: string; password: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const { data } = await authAPI.login(email, password)

			if (data.token) {
				const decToken: jwtInterface = jwtDecode(data.token)
				Cookies.set('token', data.token)
				Cookies.set('refreshToken', data.refreshToken)
				Cookies.set('expiration', data.expiration)
				Cookies.set('role', decToken.Role)
				dispatch(
					setAuthData({
						role: decToken.Role,
						email: decToken.Email,
						userId: decToken.UserId,
					})
				)
				dispatch(setAuth(true))
				openSuccessNotification('Успешная авторизация')
			}
		} catch (err: any) {
			openErrorNotification('Ошибка авторизации')
			console.error(err)
		}

		dispatch(loadingStatus(false))
	}

export const logOut = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		Cookies.remove('token')
		Cookies.remove('refreshToken')
		Cookies.remove('expiration')
		Cookies.remove('role')
		dispatch(
			setAuthData({
				role: null,
				email: null,
				userId: null,
			})
		)
		dispatch(setAuth(false))
	} catch (err: any) {
		openErrorNotification('Ошибка выхода')
		console.error(err)
	}

	dispatch(loadingStatus(false))
}
export const registration = (data: any) => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		dispatch(setAuth(true))
		openSuccessNotification('Успешная регистрация')
	} catch (err: any) {
		openErrorNotification('Ошибка регистрации')
		console.error(err)
	}

	dispatch(loadingStatus(false))
}

export default authReducer.reducer
