import qs from 'qs'

import { IDeviceAdd, IDeviceEdit } from '@/types'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { instance } from '@/api'

const getCounterDeviceUrl = (url: string): string => `counter_device/${url}`

export const CounterDeviceService = {
	async add(body: IDeviceAdd, closeModal: () => void) {
		try {
			await instance.post(getCounterDeviceUrl('add_device'), body)
			openSuccessNotification('Девайс добавлен')
			closeModal()
		} catch (error) {
			openErrorNotification('Ошибка добавления устройства')
		}
	},
	async editById(body: IDeviceEdit, closeModal: () => void) {
		try {
			await instance.patch(getCounterDeviceUrl('edit_device'), body)
			openSuccessNotification('Девайс изменен')
			closeModal()
		} catch (error) {
			openErrorNotification('Ошибка изменения устройства')
		}
	},
	async deleteById(id: string) {
		try {
			await instance.delete(getCounterDeviceUrl('delete_device'), {
				params: {
					CounterDeviceId: id,
				},
			})
			openSuccessNotification('Устройство удалено')
		} catch (error: any) {
			openErrorNotification(error.response.data.message)
		}
	},
	async getById(id: string) {
		try {
			const { data } = await instance.get(getCounterDeviceUrl('get_device'), {
				params: {
					IdCounterDevice: id,
				},
			})
			return data
		} catch (error) {
			openErrorNotification('Ошибка получения устройства')
		}
	},
	async getAll() {
		try {
			const { data } = await instance.get(
				getCounterDeviceUrl('get_all_devices')
			)
			return data
		} catch (error) {
			openErrorNotification('Ошибка получения устройств')
		}
	},
	async getCountEntersExitsPeriod(query: {
		StartDate?: string
		EndDate?: string
		CounterDeviceIds?: number[]
	}) {
		try {
			const { data } = await instance.get(
				getCounterDeviceUrl('get_count_enters_exits_period'),
				{
					params: query,
					paramsSerializer: (params) => {
						return qs.stringify(params)
					},
				}
			)
			return data
		} catch (error) {
			openErrorNotification('')
		}
	},
	async getReport() {
		try {
			instance
				.request({
					url: getCounterDeviceUrl('get_report'),
					method: 'GET',
					responseType: 'blob',
				})

				.then(({ data }) => {
					const downloadUrl = window.URL.createObjectURL(new Blob([data]))
					const link = document.createElement('a')
					console.log(link, data)

					link.href = downloadUrl
					link.setAttribute(
						'download',
						`${new Date().toLocaleDateString()}.xlsx`
					)
					document.body.appendChild(link)
					link.click()
					link.remove()
				})
		} catch (error: any) {
			openErrorNotification(error.response.data.message)
			return error
		}
	},
}
