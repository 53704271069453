import { LineChartOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Select,
	Table,
	TableColumnsType,
	TimeRangePickerProps,
	Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts'

import { CounterDeviceService } from '@/services'

import { ICounter, IDevice } from '@/types'

export const ReportScreen = () => {
	const { RangePicker } = DatePicker
	const [props, setProps] = useState<{
		StartDate?: string
		EndDate?: string
		CounterDeviceIds?: number[]
	}>({
		StartDate: '',
		EndDate: '',
		CounterDeviceIds: [],
	})
	const [counter, setCounter] = useState<ICounter[] | undefined>(undefined)
	const [device, setDevice] = useState<IDevice[]>([])

	const fetch = async () => {
		const device = await CounterDeviceService.getAll()
		setDevice(device)
	}

	useEffect(() => {
		fetch()
	}, [])

	const getCounter = async () => {
		const counter: ICounter[] =
			await CounterDeviceService.getCountEntersExitsPeriod(props)
		setCounter(
			counter.sort((a, b) => {
				return new Date(a.date).getTime() - new Date(b.date).getTime()
			})
		)
	}

	const onRangeChange = (
		dates: null | (Dayjs | null)[],
		dateStrings: string[]
	) => {
		if (dates) {
			setProps({
				...props,
				StartDate: dateStrings[0],
				EndDate: dateStrings[1],
			})
		} else {
			setProps({
				...props,
				StartDate: '',
				EndDate: '',
			})
		}
	}

	const handleChange = (value: number[]) => {
		setProps({
			...props,
			CounterDeviceIds: value,
		})
	}

	return (
		<section className="report">
			<h1 className="report__title _h3">Отчеты</h1>
			<div className="report__top">
				<div className="report__top-left">
					<RangePicker
						presets={rangePresets}
						onChange={onRangeChange}
						disabledDate={(current) => {
							return moment().add(0, 'days') < current
						}}
					/>
					<Select
						mode="multiple"
						placeholder="Счетчики"
						onChange={handleChange}
						style={{ width: '250px' }}
						options={device
							.sort((a, b) => {
								return a.name.localeCompare(b.name)
							})
							.map((item) => ({
								label: item.name,
								value: item.id,
							}))}
					/>
					<Button onClick={getCounter} disabled={!props.StartDate}>
						Сформировать
					</Button>
				</div>
				<div className="report__top-right">
					<Button
						type="primary"
						className="report__btn-exel"
						icon={<LineChartOutlined />}
						onClick={CounterDeviceService.getReport}
					>
						Выгрузить в Excel
					</Button>
				</div>
			</div>
			{counter !== undefined && counter.length ? (
				<>
					<div className="report__graphic">
						<ResponsiveContainer width="100%" height={400}>
							<LineChart data={counter}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" />
								<YAxis />
								<Tooltip />
								<Line type="monotone" dataKey="enters" stroke="#8884d8" />
								<Line type="monotone" dataKey="exits" stroke="#82ca9d" />
							</LineChart>
						</ResponsiveContainer>
					</div>
					<div className="report__table">
						<Table
							dataSource={counter}
							columns={columns}
							pagination={false}
							bordered={true}
							rowKey="date"
							style={{ maxWidth: '520px' }}
							tableLayout={'fixed'}
							locale={{ emptyText: 'Нет данных' }}
						/>
					</div>
				</>
			) : counter ? (
				<h2 className="_h4">Нет данных</h2>
			) : (
				<h2 className="_h4">Выберите даты</h2>
			)}
		</section>
	)
}

const columns: TableColumnsType<ICounter> = [
	{
		title: 'Дата',
		key: 'date',
		dataIndex: 'date',
	},
	{
		title: 'Входы',
		key: 'enters',
		dataIndex: 'enters',
	},
	{
		title: 'Выходы',
		key: 'exits',
		dataIndex: 'exits',
	},
]

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Последние 7 дней', value: [dayjs().add(-7, 'd'), dayjs()] },
	{ label: 'Последние 14 дней', value: [dayjs().add(-14, 'd'), dayjs()] },
	{ label: 'Последние 30 дней', value: [dayjs().add(-30, 'd'), dayjs()] },
	{ label: 'Последние 90 дней', value: [dayjs().add(-90, 'd'), dayjs()] },
]
