import { useNavigate } from 'react-router-dom'

import styles from './NotFound.module.scss'

export const NotFound = (): JSX.Element => {
	const navigate = useNavigate()
	return (
		<div className={styles.wrapper}>
			<h1>404</h1>
			<h2>Неправильно набран адрес или такой страницы не существует</h2>
			<p onClick={() => navigate('/')}>На главную</p>
		</div>
	)
}
