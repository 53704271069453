import { CloseOutlined, LogoutOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'

import { COUNT_PATH, MAIN_PATH, REPORT_PATH } from '@/const'

import { isShowMenu, logOut, useAppDispatch, useAppSelector } from '@/store'

export const Aside = () => {
	const isMob = useAppSelector((state) => state.app.isShowMenu)
	const dispatch = useAppDispatch()

	const onLogOut = () => {
		dispatch(logOut())
	}

	const onCloseMenu = () => {
		dispatch(isShowMenu(false))
		document.getElementById('body')?.classList.remove('_fixed')
	}

	return (
		<aside className={`aside ${isMob ? '_open' : ''}`}>
			<Button
				onClick={onCloseMenu}
				icon={<CloseOutlined />}
				className="aside__menu-btn"
			></Button>
			<ul className="aside__list">
				<li className="aside__item">
					<NavLink className={'aside__link'} to={MAIN_PATH}>
						Общий
					</NavLink>
				</li>
				<li className="aside__item">
					<NavLink className={'aside__link'} to={COUNT_PATH}>
						Счетчики
					</NavLink>
				</li>
				<li className="aside__item">
					<NavLink className={'aside__link'} to={REPORT_PATH}>
						Отчеты
					</NavLink>
				</li>
				<li className="aside__item">
					<button className={'aside__link'} onClick={onLogOut}>
						<LogoutOutlined style={{ marginRight: '10px' }} />
						Выйти
					</button>
				</li>
			</ul>
		</aside>
	)
}
