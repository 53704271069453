import { Button } from 'antd'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'

import { MAIN_PATH } from '@/const'

import { requiredField } from '@/utils'

import { login, useAppDispatch, useAppSelector } from '@/store'

type Inputs = {
	email: string
	password: string
}

export const AuthScreen = () => {
	const isAuth = useAppSelector((state) => state.auth.isAuth)

	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>()

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		dispatch(login(data))
	}

	if (isAuth) {
		return <Navigate to={MAIN_PATH} replace={true} />
	}

	return (
		<section className="login">
			<div className="login__wrap">
				<div className="login__title _h3">Авторизация</div>
				<div className="login__inner">
					<div className="login__input-wrap input">
						<label className="input__label" htmlFor="email">
							Email
						</label>
						<input
							{...register('email')}
							className="input__input"
							type="email"
							placeholder="Ваша почта"
							id="email"
						/>
						{errors.email && (
							<div className="input__error">{errors.email.message}</div>
						)}
					</div>
					<div className="login__input-wrap input">
						<label className="input__label" htmlFor="pass">
							Пароль
						</label>
						<input
							{...register('password', requiredField)}
							className="input__input"
							type="password"
							placeholder="Ваш пароль"
							id="pass"
						/>
						{errors.password && (
							<div className="input__error">{errors.password.message}</div>
						)}
					</div>
				</div>
				<Button
					className="login__btn"
					type="primary"
					onClick={handleSubmit(onSubmit)}
				>
					Авторизоваться
				</Button>
			</div>
		</section>
	)
}
