import { Navigate, Outlet } from 'react-router-dom'

import { Aside } from '@/components'

import { AUTH_PATH } from '@/const'

import { useAppSelector } from '@/store'

export const LayoutPages = () => {
	const isAuth = useAppSelector((state) => state.auth.isAuth)

	if (!isAuth) {
		return <Navigate to={AUTH_PATH} />
	}

	return (
		<div className="pages">
			<Aside />

			<div className="pages__inner">
				<Outlet />
			</div>
		</div>
	)
}
