import { useEffect, useState } from 'react'

import { CounterDeviceService } from '@/services'

import { ICounter, IDevice } from '@/types'

export const HomeScreen = () => {
	const [device, setDevice] = useState<IDevice[]>([])
	const [counter, setCounter] = useState<ICounter>()

	const fetch = async () => {
		const device = await CounterDeviceService.getAll(),
			counter = await CounterDeviceService.getCountEntersExitsPeriod({
				StartDate: new Date().toLocaleDateString('en-ca'),
				EndDate: new Date().toLocaleDateString('en-ca'),
			})
		setDevice(device)
		setCounter(counter[0])
	}

	useEffect(() => {
		fetch()
	}, [])

	return (
		<section className="home">
			<h1 className="home__title _h3">Общий</h1>
			<div className="home__inner">
				<p className="home__subtitle">
					Торговый центр "Город Локомотивов" г. Оренбург
				</p>
				<div className="home__logo">
					<img
						className="home__logo-img"
						src="/logo.png"
						alt="logo"
						width="340"
						height="70"
					/>
				</div>
				<ul className="home__list">
					<li className="home__item">
						<p className="home__item-title">Посетители сегодня</p>
						<div className="home__item-inner">
							<div className="home__item-box">
								<span>Вход</span>
								<span>{counter ? counter?.enters : 0}</span>
							</div>
							<div className="home__item-box">
								<span>Выход</span>
								<span>{counter ? counter?.exits : 0}</span>
							</div>
						</div>
					</li>
					<li className="home__item">
						<p className="home__item-title">Счетчики</p>
						<div className="home__item-inner">
							<div className="home__item-box">
								<span>В сети</span>
								<span>
									{device.filter((item) => item.status === 'Online').length}
								</span>
							</div>
							<div className="home__item-box">
								<span>Не в сети</span>
								<span>
									{device.filter((item) => item.status === 'Offline').length}
								</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>
	)
}
