import { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from 'routes'

import { setTypeApp, useAppDispatch } from './store'

function App() {
	const dispatch = useAppDispatch()

	const [width, setWidth] = useState<number>(window.innerWidth)

	useEffect(() => {
		if (width < 769) {
			dispatch(setTypeApp('mob'))
		}
	}, [dispatch, width])

	window.onresize = () => {
		setWidth(window.innerWidth)
		if (width < 769) {
			dispatch(setTypeApp('mob'))
		} else {
			dispatch(setTypeApp('desk'))
		}
	}

	return <RouterProvider router={router} />
}

export default App
