import { MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { isShowMenu, useAppDispatch } from '@/store'

export const Header = () => {
	const dispatch = useAppDispatch()

	const onShowMenu = () => {
		dispatch(isShowMenu(true))
		document.getElementById('body')?.classList.add('_fixed')
	}
	return (
		<div className="header">
			<div className="container">
				<div className="header__inner">
					<Button
						onClick={onShowMenu}
						className="header__burger"
						icon={<MenuOutlined />}
					></Button>
				</div>
			</div>
		</div>
	)
}
